.Logo {
    max-width: 270px;
    width: 80%;
    margin-bottom: 4vh;
}

.AppStoreContainer {
    text-align: center;
    line-height: 0;
    cursor: pointer;
}

.AppStoreContainer:first-of-type {
    margin-top: 15px;
}

.AppStoreContainer:hover {
    opacity: 0.9;
}

.AppStoreIcon {
    width: 180px;
}

.PlayStoreIcon {
    width: 205px;
}

.Wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 5vh 5vw;
    background: url(./bg.svg) center repeat;
}

.Container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 2% 4%;
    text-align: center;
    width: 100%;
    max-width: 700px;
    box-sizing: border-box;
    margin-bottom: 40px;
    border: 1px #EAEAEA;
    height: unset;
    overflow: visible;
    box-shadow: 0 2px 1px rgba(0, 0, 0, 0.1);
    border-radius: 6px;
    background-color: #FFFFFF;

}

.TagLine {
    margin-bottom: 20px;
    font-style: italic;
}
