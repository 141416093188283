body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: 'Sen', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif;
  min-height: 100%;
  color: #333333;
}

html {
  min-height: 100%;
  background: #f0f4f5;
}

h2 {
  font-weight: 700;
}

a {
  color: rgb(91, 33, 120);
}
